import "core-js/modules/es6.object.assign.js";

export default class StringCutter {
  constructor(el, options) {
    this.elements = document.querySelectorAll(el);
    this.options = this._parseOption(options);
    this.lines = this._detectBreakoutLines(this.options);

    this._cacheStrings(this.elements);
    this._cutString(this.elements, this.lines, this.options.ellipsis);
  }

  _parseOption(options) {
    let defaultOptions = {
      lines: 2,
      ellipsis: "..."
    };
    return Object.assign(defaultOptions, options);
  }

  _detectBreakoutLines(options) {
    let lines;
    if (
      typeof options.breakpoints === "object" &&
      Object.keys(options.breakpoints).length > 0
    ) {
      for (let query in options.breakpoints) {
        if (window.matchMedia(query).matches) {
          lines = options.breakpoints[query];
        } else {
          lines = options.lines;
        }
      }
    } else {
      lines = options.lines;
    }
    return lines;
  }

  /**
   * Store element text in memory
   * @param elements
   * @private
   */

  _cacheStrings(elements) {
    for (let i = 0, length = elements.length; i < length; i++) {
      if (!elements[i].dataset.cachedText) {
        //prevent from repeated caching
        elements[i].dataset.cachedText = elements[i].innerHTML.trim(); //Store cached text in element "cachedText" property
      }
    }
  }

  /**
   *
   * @param {HTMLElement} el - Element
   * @return {number} pixels from elements top to parent top
   * @private
   */

  _getRelativePos(el) {
    return (
      el.offsetTop +
      el.offsetHeight -
      (el.parentNode.offsetTop + el.parentNode.offsetHeight)
    );
  }

  /**
   * cut long strings!
   * @param {HTMLElement} elements
   * @param {Number} lines
   * @param {String} ellipsis
   * @private
   */

  _cutString(elements, lines, ellipsis) {
    for (let i = 0, length = elements.length; i < length; i++) {
      let element = elements[i];
      let cachedText = element.dataset.cachedText; //get stored string from property
      element.innerHTML =
        "<span>" + cachedText.split(" ").join(" </span><span>") + "<span>"; //wrap each word in string with <span>
      let refPos = this._getRelativePos(element.querySelector("span")); //get position of first element

      let newPos;
      let lineCounter = 1;

      let spans = element.querySelectorAll("span");
      let skip = false;

      for (let i = 1, length = spans.length; i < length; i++) {
        let currentSpan = spans[i];
        let prev = currentSpan.previousElementSibling;
        if (!prev) {
          skip = true;
        }
        if (!skip) {
          //empty spans can cause a problems with detecting their positions
          //so just skipping it
          if (currentSpan.innerHTML === " ") {
            newPos = refPos;
          } else {
            newPos = this._getRelativePos(currentSpan);
          }
          /**
           * if position of span is equal position of previous span()
           * we're on the same line.
           * add text to previous span and remove current span
           */
          if (newPos === refPos) {
            prev.innerText += currentSpan.innerText;
            currentSpan.parentNode.removeChild(currentSpan);
          }
          //new line!
          if (newPos > refPos) {
            prev.innerHTML += "<br>";
            lineCounter++;
          }
          //skip if we don't need more lines and remove last span(because it is on new line)
          if (lineCounter > lines && lineCounter !== 1) {
            prev.innerText =
              prev.innerText.substr(
                0,
                prev.innerText.length - ellipsis.length - 1
              ) + ellipsis;
            currentSpan.parentNode.removeChild(currentSpan);
            skip = true;
          }

          refPos = newPos;
        } else {
          currentSpan.parentNode.removeChild(currentSpan);
        }
      }
    }
  }

  /**
   * Update cutter
   * @example
   * window.addEventListener("resize",function(){
   *   stringCutter.update();
   * });
   */
  update() {
    let lines = this._detectBreakoutLines(this.options);
    this._cutString(this.elements, lines, this.options.ellipsis);
  }

  static isCssCut() {
    let cutRes;

    if (typeof CSS === "function") {
      cutRes = !CSS.supports("-webkit-line-clamp", "2");
    } else {
      cutRes = true;
    }

    return cutRes;
  }

  static fontsLoaded(callback) {
    if (document.fonts === undefined || typeof document.fonts !== 'object') return false;
    document.fonts.addEventListener('loadingdone', () => callback);
  }
}
