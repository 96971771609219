require("./index.scss");

import getCookie from "../../modules/szfront_utils/getCookie";
import setCookie from "../../modules/szfront_utils/setCookie";
import event from "../../modules/szfront_utils/event";

const elScore = document.querySelector('.js-score');
const cookieName = 'showScore';

if (elScore && !getCookie(cookieName)) {
  const elsClose = [].slice.call(document.querySelectorAll('.js-score-close'));
  const visibleCssClass = 'score_visible';
  elScore.classList.add(visibleCssClass);

  elsClose.forEach(el => {
    el.addEventListener('click', e => {
      elScore.classList.remove(visibleCssClass);
      setCookie(cookieName, 1)
    })
  });

  // Analytics:
  function on(className, eventName) {
    document.querySelector(`.${className}`).addEventListener('click', () => { event.trigger(eventName) });
  }

  event.trigger('score-show-dialog');
  on('js-score-event-close-button', 'score-close-button');
  on('js-score-event-close-outside', 'score-close-outside');
  on('js-score-event-send-suggestions', 'score-send-suggestions');
  on('js-score-event-rate', 'score-rate');
}
