/**
 * Debouncing execution of any function
 * @example
 *  var generateScroll = debounce(function() {
    window.dispatchEvent(ScrollEvent);
  }, 100);
 *
 * @param {Function} func - Function to execute
 * @param {Number} wait - ms to wait
 * @param {Boolean} immediate - run immediately
 * @return {Function}
 */
let debounce = function (func, wait, immediate = false) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

export default debounce;
