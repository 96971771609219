import getClosest from "../szfront_utils/getClosest";

export default class Scroller {
  /**
   * Initialize scroller;
   * @param el - Slider wrapper element
   * @param selectors
   */
  constructor(el, selectors) {
    this.selectors = selectors || {
      container: '.m-slider__cnt',
      scrolled: '.m-slider__container',
      item: '.m-slider__item',
      dots: '.m-slider__dots',
      dotSelectedCN: 'm-slider__dots--selected',
      dotStepCN: 'm-slider__dots--step-',
      prevButton: '.m-slider__prev-btn',
      nextButton: '.m-slider__next-btn',
    };

    this.el = el;
    this.container = el.querySelector(this.selectors.container); // Контейнер
    this.scrolled = el.querySelector(this.selectors.scrolled); // Двигаемый контейнер
    this.sliderItem = el.querySelector(this.selectors.item); // Ячейка (для определения размера)
    this.dots = el.querySelector(this.selectors.dots); // Точки
    this.prevButton = el.querySelector(this.selectors.prevButton);
    this.nextButton  = el.querySelector(this.selectors.nextButton);

    this.sliderPosition = "0";  // Начальная позиция
    this.prevState = 0;         // Текущее состояние слайдера (для проверки)
    this.isSlide = false;
    this.touchX = 0;
    this.slideActive = 0;

    this.resizeCarousel();
    this.activeDot = 1;

    /** Responsive */
    window.addEventListener("resize", this.resizeCarousel.bind(this)); // todo: debounce this

    /** Button Events */
    this.prevButton.addEventListener('click', () => this.slide('prev'));
    this.nextButton.addEventListener('click', () => this.slide('next'));

    /** Touch Control */
    this.scrolled.addEventListener("touchstart", this.slideStart.bind(this));
    document.addEventListener("touchmove", this.slideMove.bind(this));
    document.addEventListener("touchend", this.slideEnd.bind(this));

    /**  Mouse Control */
    this.scrolled.addEventListener("mousedown", this.slideStart.bind(this));
    document.addEventListener("mousemove", this.slideMove.bind(this));
    document.addEventListener("mouseup", this.slideEnd.bind(this));
  }

  get containerWidth() {
    let { container } = this;
    let containerStyles = getComputedStyle(container);
    return container.clientWidth - parseInt(containerStyles.paddingLeft) - parseInt(containerStyles.paddingRight);
  }

  resizeCarousel() {
    // todo: сделать восстанавление текущей позиции
    let { el, containerWidth, scrolled, dots } = this;
    scrolled.style.width = "";

    if (scrolled.clientWidth < containerWidth) {
      scrolled.style.width = "100%";
      scrolled.style.left = "0";
      dots.style.opacity = "0";
      el.classList.add('full');
    } else {
      dots.style.opacity = "1";
      el.classList.remove('full');
    }
  }

  get faultWidth() {
    let { sliderItem } = this;
    let width = 0;
    ['marginLeft', 'marginRight'].map((selfMargin) => {
      width += parseInt(window.getComputedStyle(sliderItem)[selfMargin]);
    });
    return width;
  }

  get itemWidth() {
    let { sliderItem, faultWidth } = this;
    return sliderItem.clientWidth + faultWidth;
  }

  set sliderPosition(pos) {
    this.scrolled.style.left = pos + "px";
  }

  get sliderPosition() {
    return parseInt(this.scrolled.style.left);
  }

  set activeDot(state) {
    let { scrolled, containerWidth, selectors } = this;
    if ((scrolled.clientWidth > containerWidth) && (state !== this.prevState)) {
      if (document.querySelector("." + selectors.dotSelectedCN))
        document.querySelector("." + selectors.dotSelectedCN).className = selectors.dotStepCN + this.prevState;
      document.querySelector("." + selectors.dotStepCN + state).className += " " + selectors.dotSelectedCN;
      this.prevState = state;
    }
  }

  slide(direction) {
    let { itemWidth } = this;
    let x = this.sliderPosition;
    switch (direction) {
      case "prev":
        x += itemWidth;
        break;
      case "next":
        x -= itemWidth;
        break;
    }
    this.sliderPosition = x;
    this.slideEnd();
  }

  static eventPageX(e) {
    // Проверяем тач это или клик
    if(e.changedTouches) {
      return e.changedTouches[0].pageX
    } else {
      e.preventDefault();
      return e.pageX;
    }
  }

  slideStart(e) {
    let { scrolled } = this;
    scrolled.style.transition = "0s";

    this.touchX = Scroller.eventPageX(e) - this.sliderPosition;
    this.isSlide = true;
  }

  slideMove(e) {
    if (this.isSlide) {
      this.sliderPosition = Scroller.eventPageX(e) - this.touchX;
    }
  }

  slideEnd() {
    if (this.isSlide) {
      this.touchX = 0;
      this.isSlide = false;
      this.scrolled.style.transition = "";
    }

    this.correction();
  }

  correction() {
    let { scrolled, containerWidth, faultWidth, itemWidth } = this;

    let state = 1; // Состояние слайдера

    /** Корректировка положения карусели */
    let x = this.sliderPosition; // Получаем позицию компонентов
    let position = Math.round(x / itemWidth) * itemWidth; // Устанавливаем точную позицию куда должен сдвинуться контейнер

    /** Процесс корректировки карусели, высчитываем три этапа прокрутки (начало-середина-конец) */
    if (x > -scrolled.clientWidth + containerWidth) {
      state = (x > - itemWidth / 2) ? 1 : (x < -scrolled.clientWidth - faultWidth + itemWidth / 2) ? 3 : 2; // середина
    } else { // конец
      position = -scrolled.clientWidth + containerWidth;
      state = 3;
    }

    if (x > 0) {
      position = 0; // начало
    }

    this.sliderPosition = position;
    this.activeDot = state;
  }
}
