/**
 *
 * @param {Element} el - Spoiler Button
 * @param toggle
 * @constructor
 */
const Spoiler = function(el, toggle = false) {
  let spoilerContent = el.parentNode;
  let activeClass = spoilerContent.classList[0] + "_active";
  let method = toggle ? "toggle" : "add";

  el.addEventListener("click", function(e) {
    e.preventDefault();
    spoilerContent.classList[method](activeClass);
  });
};

export default Spoiler;
