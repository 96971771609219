const event = (function() {
  function trigger(event, detail = false) {
    let eventObj;
    if (!detail) {
      eventObj = new Event(event, { bubbles: false, cancelable: false });
    } else {
      eventObj = new CustomEvent(event, { detail: detail });
    }
    document.dispatchEvent(eventObj);
  }

  function on(event, callback) {
    document.addEventListener(event, callback);
  }

  function rm(event) {
    document.removeEventListener(event);
  }

  return {
    trigger: trigger,
    on: on,
    rm: rm
  };
})();

export default event;
