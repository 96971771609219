require("./index.scss");
require("./../common/video.scss");
require("./../common/page-info.scss");

import event from "../../modules/szfront_utils/event";
import StringCutter from "../../modules/szfront_utils/StringCutter";
import debounce from "../../modules/szfront_utils/debounce";
import spoiler from "../../modules/szfront_utils/spoiler";
import nextChannels from "./nextChannels";

event.on("page_main_index:init", function() {
  let spoilerBtn = document.querySelector(".page-info__read-more");
  if (spoilerBtn) {
    spoiler(spoilerBtn);
  }

  if (StringCutter.isCssCut()) {
    let titles = ".video__title";
    let options = {
      lines: 4,
      ellipsis: "...",
      breakpoints: {
        "(min-width: 640px)": 2
      }
    };

    let titleCutter = new StringCutter(titles, options);

    StringCutter.fontsLoaded(() => titleCutter.update());
    window.addEventListener("resize", debounce(titleCutter.update.bind(titleCutter), 500));
  }

  nextChannels();
});
