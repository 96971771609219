import ajaxGet from "../../modules/szfront_utils/ajaxGet";
import debounce from "../../modules/szfront_utils/debounce";

function nextChannels () {
  const next = (channels) => {
    let lastSection = document.querySelector('section.section:last-of-type');

    channels.forEach(item => {
      lastSection = document.querySelector('section.section:last-of-type');
      
      let clonedSection = lastSection.cloneNode(true);
      let list = clonedSection.querySelector('.section__video-list');
      list.innerHTML = '';

      let sectionLink = clonedSection.querySelector('.section__btn-link');
      let iconLink = clonedSection.querySelector('.section__icon');
      let iconImg = clonedSection.querySelector('.section__icon img');
      let title = clonedSection.querySelector('.section__title a');

      sectionLink.href = item.link;
      iconLink.href = item.link;
      title.href = item.link;
      title.innerHTML = item.name;

      iconImg.removeAttribute('data-normal');
      iconImg.removeAttribute('data-srcset');
      iconImg.src = item.thumbnail_url;
      iconImg.srcset = item.thumbnail_srcset;


      item.index_videos.forEach(video => {
        let videoItem = document.querySelector('.section__video').cloneNode(true);

        let title = videoItem.querySelector('.video__title');
        let length = videoItem.querySelector('.video__length');
        let link = videoItem.querySelector('.video.video_tile');
        let img = videoItem.querySelector('.video__thumb-img');

        title.innerHTML = video.name;
        length.innerHTML = video.duration_minutes;
        link.href = video.permanent_path;
        img.src = video.thumbnail_url;
        img.srcset = video.thumbnail_srcset;
        img.removeAttribute('data-normal');
        img.removeAttribute('data-srcset');

        let list = clonedSection.querySelector('.section__video-list');
        list.innerHTML


        list.insertAdjacentElement('beforeend', videoItem);
      });

      lastSection.insertAdjacentElement('afterend', clonedSection);
    });
  };

  const moreEl = document.querySelector('.section__more');
  let blocked = false;

  const load = () => {
    let scrolled = window.pageYOffset || document.documentElement.scrollTop;

    if (scrolled + window.innerHeight >= moreEl.offsetTop && !blocked ) {
      blocked = true;

      ajaxGet(moreEl.dataset.path)
        .then(data => {
          let dataJson = JSON.parse(data);
          moreEl.dataset.path = dataJson.next_channels_path;

          if (dataJson.channels.length && dataJson.channels.length >= 3) {
            blocked = false;
            next(dataJson.channels);
          } else {
            moreEl.classList.remove('section__more_waiting');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  window.addEventListener('scroll', debounce(load, 300).bind(this));
};

export default nextChannels;
